import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoggedinGuard } from './guards/loggedin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'wellcome',
    pathMatch: 'full'
  },
  {
    path: 'wellcome',
    canActivate: [LoggedinGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'user-registration',
    canActivate: [LoggedinGuard],
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'thank-you',
    canActivate: [LoggedinGuard],
    loadChildren: () => import('./registration/wellcome/wellcome.module').then(m => m.WellcomeModule)
  },
  {
    path: 'sign-in',
    canActivate: [LoggedinGuard],
    loadChildren: () => import('./auth/sign-in/sign-in.module').then(m => m.SignInModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'we-are-very-sorry',
    loadChildren: () => import('./errors/country-not-enabled/country-not-enabled.module').then(m => m.CountryNotEnabledModule)
  },
  {
    path: 'error-authorization',
    loadChildren: () => import('./errors/error-authorization/error-authorization.module').then(m => m.ErrorAuthorizationModule)
  },
  {
    path: 'same-post',
    loadChildren: () => import('./errors/same-post/same-post.module').then(m => m.SamePostModule)
  },
  {
    path: 'error-500',
    loadChildren: () => import('./errors/connection-error/connection-error.module').then(m => m.ConnectionErrorModule)
  },
  {
    path: '**',
    loadChildren: () => import('./errors/not-found/not-found.module').then(m => m.NotFoundModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
