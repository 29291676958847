import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {
    
    constructor(private router: Router, private _userService: UserService, private _globalService: GlobalService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let request = req;


        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                console.log(err.status);
                if (err.status === 400) {
                    this._userService.removeSecureStorage('token');
                    this._userService.getTokenUser(undefined);
                    this.router.navigateByUrl('/sign-in');
                }

                if (err.status === 401) {
                    this._userService.removeSecureStorage('token');
                    this._userService.getTokenUser(undefined);
                    this.router.navigateByUrl('/sign-in');
                }

                if (err.status === 403) {
                    this._userService.removeSecureStorage('token');
                    this._userService.getTokenUser(undefined);
                    this.router.navigateByUrl('/sign-in');
                }

                if (err.status === 407) {
                    this.router.navigateByUrl('/error-authorization');
                }

                if (err.status === 500) {
                    this.router.navigateByUrl('/error-500');
                }

                if (err.status === 0) {
                    this.router.navigateByUrl('/error-500');
                }


                return throwError(err);

            })
        );
    }

}