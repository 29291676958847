import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Platform } from '@ionic/angular';
import jwt_decode from 'jwt-decode';
import { UserService } from './services/user.service';
import { WebsocketService } from './services/websocket.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public userActice: any;

  constructor(private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private _webSocket: WebsocketService,
    private _userService: UserService) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      setTimeout(() => {
        this.splashScreen.hide();
      }, 300);
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#141416');
      this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
    });
  }

  ngOnInit() {
    this.checkUser();
  }

  checkUser() {
    this._userService.getUserToken().subscribe(res => {
      let tokeInfo = JSON.parse(res);
      if (tokeInfo !== false) {
        let decoded = jwt_decode(tokeInfo.token);
        this.userActice = JSON.stringify(decoded);
        this.userActice = JSON.parse(this.userActice);
        this._userService.getTokenUser(tokeInfo.token);

      } else {

      }
    });
  }

}
