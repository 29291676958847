import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})

export class LoggedinGuard implements CanActivate {
  public userActice: any;
  constructor(private _userService: UserService, private _router: Router) { this.checkUser()}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._userService.isLoggedIn() === true) {
      console.log('Hola 2');
      this._router.navigate(['/home/tabs/tab1']);
      return true;
    }
  }

  checkUser() {
    this._userService.getUserToken().subscribe(res => {
      let tokeInfo = JSON.parse(res);
      if (tokeInfo !== false) {
        let decoded = jwt_decode(tokeInfo.token);
        this.userActice = JSON.stringify(decoded);
        this.userActice = JSON.parse(this.userActice);
        this._userService.getTokenUser(tokeInfo.token);

      } else {

      }
    });
  }

}