import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, from, of } from 'rxjs/index';
import { environment } from 'src/environments/environment.prod';
import { Storage } from '@ionic/storage-angular'
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import * as CryptoJS from 'crypto-js';
import { AES, SHA256 } from 'crypto-js';
import { filter } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _url: string;
  private _secret_key: string;
  public token: any;
  private storageReady = new BehaviorSubject(false);

  constructor(private _http: HttpClient, private storage: Storage) {
    this._url = environment.url;
    this._secret_key = environment.secret_key;
    this.initStorage();
  }

  async initStorage(){
    await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();
    this.storageReady.next(true);
  }

  
  getUserData() {
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => {
        return from(this.getSecureStorage('token')) || of ([]);
      })
    )
  }

  getUserToken() {
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => {
        return from(this.getSecureStorage('token')) || of ([]);
      })
    )
  }

  setSecureStorage(key: string, value) {
    this.storage.set(SHA256(key).toString(), this._encrypt(value)) || of ([])
  }

  async getSecureStorage(key) {
    const value = await this.storage.get(SHA256(key).toString());
    return (value) ? this._decrypt(value) : false;
  }

  removeSecureStorage(key) {
    this.storage.remove(SHA256(key).toString());
  }

  _encrypt(value) {
    return AES.encrypt(value, this._secret_key).toString();
  }

  _decrypt(key) {
    const bytes = AES.decrypt(key, this._secret_key) || false;
    try {
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      return false;
    }
  }

  getTokenUser(token: string){
    this.token = token;
  }

  isLoggedIn(){
    if(this.token === undefined){
      return false
    }else{
      return true
    }
  }

  newUser(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.post(`${this._url}new-user`, params, { headers: headers });
  }

  updateUserData(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}update-user-data`, params, { headers: headers });
  }

  sendCode(email: string, method: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.get(`${this._url}send-code/${email}/${method}`, { headers: headers });
  }

  validateCode(email: any, cod: any, method: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.get(`${this._url}validate-code/${email}/${cod}/${method}`, { headers: headers });
  }

  newPost(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}new-post`, params, { headers: headers });
  }

  getPosts(to: any, USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-posts/${to}/${USER_ID}`, { headers: headers });
  }

  newComment(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}new-comment`, params, { headers: headers });
  }

  getFeedback(ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-feedback/${ID}`, { headers: headers });
  }

  deleteComment(ID: any, USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}delete-comment/${ID}/${USER_ID}`, { headers: headers });
  }

  deletePost(ID: any, USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}delete-post/${ID}/${USER_ID}`, { headers: headers });
  }

  getHugs(ID: any, USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-hugs/${ID}/${USER_ID}`, { headers: headers });
  }

  goHug(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}go-hug`, params, { headers: headers });
  }

  getFavorites(ID: any, USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-favourites/${ID}/${USER_ID}`, { headers: headers });
  }

  addFavorites(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}add-my-favourites`, params, { headers: headers });
  }

  getmyfavourites(to: any, USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-my-favourites/${to}/${USER_ID}`, { headers: headers });
  }

  getTotals(USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-totals/${USER_ID}`, { headers: headers });
  }

  saveFeeling(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}save-my-feeling`, params, { headers: headers });
  }

  getMyPosts(to: any, USER_ID: any, CAT: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-my-posts/${to}/${USER_ID}/${CAT}`, { headers: headers });
  }
  
  updateAvatar(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}update-my-avatar`, params, { headers: headers });
  }

  getNotification(USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-my-notification/${USER_ID}`, { headers: headers });
  }

  deleteNotification(ID: any, USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}delete-my-notification/${ID}/${USER_ID}`, { headers: headers });
  }

  getFeeling(USER_ID: any, TO: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-my-feeling/${TO}/${USER_ID}`, { headers: headers });
  }
  
  newNote(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}new-note`, params, { headers: headers });
  }

  getNotes(USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-my-notes/${USER_ID}`, { headers: headers });
  }

  deleteNote(ID: any, USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}delete-note/${USER_ID}/${ID}`, { headers: headers });
  }

  getUserDataPublic(USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-user-data-public/${USER_ID}`, { headers: headers });
  }

  getPostsPublic(USER_ID: any, USERID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-posts-from-user/${USER_ID}/${USERID}`, { headers: headers });
  }

  getPostsByTypePublic(USER_ID: any, TYPE: any, USERID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-posts-from-user-type/${USER_ID}/${TYPE}/${USERID}`, { headers: headers });
  }

  getPostsByCategory(TO: any, CAT: any, USER_ID: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-posts-by-category/${TO}/${CAT}/${USER_ID}`, { headers: headers });
  }

  searchPosts(TO: any, USER_ID: any, CATE?: string, TYPE?: string, TERM?: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}search-posts/${TO}/${USER_ID}/${CATE}/${TYPE}/${TERM}`, { headers: headers });
  }

  deleteAccount(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.post(`${this._url}delete-account`, params, { headers: headers });
  }

  getDetails(id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}get-publication-details/${id}`, { headers: headers });
  }

  deleteFeeling(idUser: any, id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.token);
    return this._http.get(`${this._url}delete-feeling/${idUser}/${id}`, { headers: headers });
  }

}
