import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, from, of } from 'rxjs/index';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private _url: string;
  private _secret_key: string;

  constructor(private _http: HttpClient) {
    this._url = environment.url;
    this._secret_key = environment.secret_key;
  }

  getUrlData(url: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.get(`https://api.linkpreview.net/?key=e03b2dcfaade4ab1834623daf6cb9d05&q=${url}`, { headers: headers });
  }

  getLocationData(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.get(`https://extreme-ip-lookup.com/json/?key=x5qMGxBRwacWaUeWSKlp`, { headers: headers });
  }

  getPais(pais: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}countries/${pais}`,
      { headers: headers });

  }

  getRegiones(id: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}region/${id}`,
      { headers: headers });

  }

  getComunas(region: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}communes/${region}`,
      { headers: headers });

  }

  getCategories(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}get-categories`,
      { headers: headers });

  }

  sendQuestion(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this._http.post(`${this._url}send-question`, params, { headers: headers });
  }

  getCategoriesPost(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    return this._http.get(`${this._url}get-categories-post`,
      { headers: headers });

  }

}
